@import 'colors.scss';

.cases {
  text-align: left;
  margin: 0;
}
.cases-table-data--type > svg {
  margin-right: 5px;
}
#dispensary-details-tabs-tabpane-cases
  > .cases
  > .cases-table
  > .cases-table--body
  > tr {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
#dispensary-details-tabs-tabpane-cases {
  border-top: 1px solid #dee2e6;
  border-top-right-radius: 4px;
}

.cases-table--body > tr {
  display: grid;
  grid-gap: 2rem;
  grid-auto-columns: minmax(max-content, 5fr);
  // height: 3rem;
  padding-left: 1rem;
  font-size: 1rem;
}
.cases,
.cases-table--body > tr,
.cases-table {
  width: 100%;
}
.cases > h2 {
  color: #0c0f13;
  margin-top: 2rem;
}

.cases > h3 {
  font-family: 'Source Sans Pro';
  margin-bottom: 0;
}
.cases-table-data,
.cases-table-headers {
  align-items: center;
}
.cases-table-headers {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.cases-table-data:not(.grid-template-columns-false) {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.cases-table-headers--not-allCases {
  align-items: center;
}
.cases-table-data {
  background-color: #fff;
}
.cases-table-data--type > img {
  width: 1.25rem;
  padding-right: 0.25rem;
}
.cases-table-data--dispensary {
  height: 1.5rem;
  width: 100%;
  justify-self: center;
  display: flex;
  align-items: center;
}
.cases-table-data--dispensary > a {
  position: relative;
  padding: 0 0.5rem;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  white-space: nowrap;
  color: #fff;
  margin: 0px auto;
}
.cases-table-headers {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4 !important;
}
.cases-button {
  width: 163px;
  height: 36px;
  font-family: 'Source Sans Pro';
  cursor: pointer;
  margin-left: 12px;
}

.cases-button:focus {
  outline: none;
}

.silver-cases-button {
  border: solid 1px #ced0da;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
}

.cases-table-headers.cases-table-headers--not-allCases {
  height: 0px;
}
.cases-table-data--priority-1.cases-table-data-0ccff7 {
  border-left: solid 0.25rem #0ccff7;
}
.cases-table-data--priority-1.cases-table-data-cases-89d011 {
  border-left: solid 0.25rem #75c699;
}
.cases-modal-footer {
  justify-content: center;
}

.cases-margin-left-true {
  margin-left: 15rem;
}
.cases-margin-left-false {
  margin-left: 0;
}
.cases-table--body > tr:not(:first-child) {
  border: solid 1px 1px 1px 0 #e6eaee;
}
// .cases-table--body > tr,
// .cases > h2,
// .cases-table-headers {
//   font-family: 'Source Sans Pro', sans-serif;
// }
.cases > h2,
.cases > h3 {
  font-size: 1.75rem;
}
.cases > h2,
.cases-table-headers,
.cases-button {
  font-weight: 600;
}
.cases > h3,
.silver-cases-button {
  color: #1c1c1c;
}
.cases-table-data--dispensary,
.cases-button,
.cases-table--body > tr:not(:first-child) {
  border-radius: 0.25rem;
}
.cases-table--body > tr > th,
.cases-button {
  font-size: 14px;
}

.cases-table-data--name {
  transition: 1s;
  overflow: hidden;
  white-space: nowrap;
  a {
    color: #000;
  }
}

.cases-table-data--name:hover {
  overflow: visible;
}

.case-list-date {
  font-size: 13px;
  position: relative;
  bottom: 1px;
  white-space: nowrap;
}

.padding-top {
  margin-top: 2rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000 !important;
  background-color: transparent !important;
  // background-color: rgba(0, 255, 0, 0.3) !important;
  border-left: 5px solid #75c699 !important;
}

.nav-link.active {
  color: #000 !important;
  background-color: transparent !important;
  // background-color: rgba(0, 255, 0, 0.3) !important;
  border-left: 5px solid #75c699 !important;
}

th.MuiTableCell-root.MuiTableCell-head {
  color: #222;
  background-image: $lightGradient1;
}

.card-header-tabs {
  margin-left: 0 !important;
  padding: 1rem;
}

.row {
  padding: 1rem;
}

.MuiGrid-spacing-xs-1 {
  margin: 0px !important;
}

.makeStyles-root-1 {
  margin-left: 10px !important;
}

.btnCustom {
  height: 2.5rem;
  margin-top: 3px;
  margin-left: 5px;
  width: 5rem;
}

.MuiPaper-rounded {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}

.MuiGrid-container {
  align-items: center;
}

.alignItem {
  margin-top: 1rem !important;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.MuiFormControl-root .form-control {
  width: 10.5rem;
}

.form-control {
  box-shadow: unset !important;
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.form-control:focus {
  border-color: #75c699 !important;
}

.ownspan {
  margin-right: 0 !important;
  float: right;
}

.dropdown-menu {
  min-width: 5rem !important;
}

.badge {
  font-size: 60% !important;
}

.badge-success {
  color: #fff;
  background-color: #75c699 !important;
}

.btn-success {
  color: #fff;
  background-color: #75c699 !important;
  border-color: #75c699 !important;
}

.tejas {
  color: #222;
  background: linear-gradient(to top, #f4f7fa, #ffffff);
}

.dark {
  .MuiTableSortLabel-root:hover,
  th.MuiTableCell-root.MuiTableCell-head,
  .MuiTableSortLabel-root.MuiTableSortLabel-active,
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    color: $light2;
  }

  th.MuiTableCell-root.MuiTableCell-head,
  .MuiToggleButton-root.Mui-selected {
    background: $darkGradient1;
  }

  .cases-table-headers {
    background-color: $dark1 !important;
  }

  table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .MuiToggleButton-root.Mui-selected {
    border: 1px solid $dark4;
  }
}
