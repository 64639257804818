$grey1: #7f8fa4;
$cannovateGreen: #75c699;

$dark1: #2b2d42;
$dark2: #41435c;
$dark3: #4d516e;
$dark4: #37394d;

$light1: #f7f8fa;
$light2: #ffffff;
$light3: #f7f8fa;
$light4: #f4f7fa;
$light5: #9294a0;

$darkGradient1: linear-gradient(
  0deg,
  #79c695,
  #79c695
) !important; //linear-gradient(0deg, $dark1, $dark4);
$lightGradient1: linear-gradient(
  0deg,
  #79c695,
  #79c695
) !important; //linear-gradient(0deg, $light4, $light2);
