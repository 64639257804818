@import 'colors.scss';
@import 'responsive.scss';

.activeNav {
  background-color: $light3;
  color: 'black';
}

.dashboard-sidepanel {
  display: flex;
  border-right: 1px solid rgba(151, 151, 151, 0.28);
  background-color: $light2;
  position: fixed;
  left: 0;
  height: 100vh;
  z-index: 1;
  @media only screen and (max-width: $large) {
    display:none !important;
  }
}

.dashboard-sidepanel--user.mobile-side-panel {
  display: none;
}

.agent-online-status {
  display: flex;
  align-items: center;
  justify-content: center;
  h5 {
    width: 65px;
  }
  label {
    margin-left: 1rem;
  }
}

.dashboard-sidepanel--user {
  margin: 1rem;
  border-radius: 4px;
  background-color: $light1;
}

.dashboard-sidepanel--user {
  width: 15rem;
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 15px;
  padding-top: 20px;
}

.dashboard-sidepanel--user-image > img {
  width: 104px;
  height: 104px;
  object-fit: cover;
  border-radius: 50%;
}

.sidepanel-agentname,
.sidepanel-service-level--text-amount {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: #0c0f13;
  margin-bottom: 10px;
  width: 13rem;
  overflow-wrap: break-word;
  margin: 0px auto;
}

.sidepanel-agentstatus {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #0c0f13;
}

.sidepanel-agentroll {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #9294a0;
}
.sidepanel-service-level--text {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 1rem;
}
.sidepanel-service-level--text-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #9294a0;
}
.sidepanel-service-level {
  margin-bottom: 38px;
}
progress {
  width: 140px;
  height: 4px;
  border-radius: 2px;
  // background: #72d400;
}
progress::-moz-progress-bar {
  background: blue;
}
progress::-webkit-progress-value {
  background: #75c699;
  // color: rgba(186, 197, 218, 0.35);
  color: red;
}
.sidepanel-nav > li {
  text-align: left;
}
.sidepanel-nav {
  margin: 1rem;
}
.sidepanel-nav > li > a {
  display: inline-block;
  line-height: 40px;
  text-decoration: none;
  height: 40px;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #9294a0;
  width: 100%;
  padding-left: 18px;
}
.sidepanel-nav > li > a > svg {
  margin-right: 18px;
}
.sidepanel-nav > li > a:active {
  background-color: $light1;
}

.sidepanel-logo {
  position: relative;
  width: 100%;
  padding-bottom: 0.6rem;
  pointer-events: none;
}

.sidepanel-logo > img {
  width: 140px;
}
.active > svg {
  color: #75c699;
}
.sidepanel-nav > li > a {
  display: grid;
  grid-template-columns: 28px auto;
  align-items: center;
}

.sidepanel-nav > li > p {
  display: grid;
  grid-template-columns: 28px auto;
  align-items: center;
  cursor: pointer;
}

.sidepanel-nav > li > p {
  display: inline-block;
  line-height: 45px;
  text-decoration: none;
  height: 45px;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #9294a0;
  width: 100%;
  padding-left: 18px;
}

.sidepanel-nav > li > p > svg {
  margin-right: 18px;
}

.dark {
  .dashboard-sidepanel--user {
    background-color: $dark1;
  }

  .sidepanel-nav > li > a:active {
    background-color: $dark1;
  }

  .dashboard-sidepanel {
    border-right: 1px solid rgba(151, 151, 151, 0.28);
    background-color: $dark2;
  }

  .activeNav {
    background-color: $dark3;
    color: $light1;
  }

  .sidepanel-agentname,
  .agent-online-false,
  .sidepanel-agentstatus {
    color: $light1;
  }

  th {
    color: $light5 !important;
  }

  td {
    color: $light4 !important;
  }
}
