#app-login {
  // background: url(/images/login-bck@3x.jpg) no-repeat center center fixed;
  background-color:#75c699;
  min-height: 100vh;
  min-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}
.app-login-wrapper,
.login-button,
.login-input {
  border-radius: 0.25rem;
}
.app-login-wrapper,
.login-form-top,
.login-form-bottom,
.forgot-password {
  width: 28.125rem;
  @media screen and (min-width:'300px') {
    width: unset;
  }
}
.login-form-top,
.login-input {
  // background-color: #f8fafc;
}
.login-form-top > span,
.login-input,
.login-label {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
.login-form-top > span,
.login-input {
  color: #1c1c1c;
}
.login-form-top > span,
.login-label {
  display: block;
}
.login-input,
.login-label,
.login-button {
  font-size: 0.875rem;
}
.login-input,
.login-button {
  width: 100%;
  height: 2.875rem;
}
.login-input,
.login-label {
  font-weight: 600;
  line-height: 1.36;
}
.app-login-wrapper {
  // position: absolute;
  // position: fixed;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  // border: solid 1px #e6eaee;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 100%;
}
.login-form-top {
  height: 100px;
}
.login-form-top > img {
  object-fit: contain;
  margin-top: 38px;
  width: 14.75rem;
  // height: 210px;
}
.login-form-top > span {
  font-size: 24px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
}
.login-form-bottom {
  background-color: #ffffff;
}
.login-form-bottom > form {
  padding: 1.875rem;
}
.login-input {
  border: solid 1px #dfe3e9;
  padding-left: 0.8125rem;
}
.login-label {
  width: 12.0625rem;
  height: 1.1875rem;
  text-align: left;
  color: #7f8fa4;
  margin: 16px 0 5px;
}
.login-button {
  border: solid 1px #75c699;
  background-image: linear-gradient(to bottom, #75c699, #2eb56a);
  margin-top: 1.25rem;
  color: white;
  font-weight: 600;
}

.app-login-wrapper .forgot-password {
  color: #75c699 !important;
  // background-color:#75c699;
  font-size: 0.875rem;
  float: right;
  line-height: 2.36;
}

.round-border {
  margin: 5%;
  padding: 5px !important;
  border-radius: 10px !important;
}


.cannovate-banner {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height:100%;
  img {
    width: 50%;
    background: white;
    border-radius: 5rem;
  }
}

.flex-1 {
  flex:1;
}

.grid-box {
  margin: 2rem;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  height: 150px;
  width: 150px;
  grid-gap: 1px;
  gap: 1px;
  
  div{
    height: 7px;
    width: 7px;
    border-radius: 5px;
    background-color: #405d84;
  }
}