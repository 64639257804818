$small: 600px;
$medium: 900px;
$large: 1220px;


.cases-table--body {
    td {
        min-width: 7rem;
        white-space: break-spaces;
        overflow-wrap: initial;
    }
}

.table-loading-container {
    margin-left: unset !important
}

@media screen and (max-width: $large) {


    .call-buttons {
        margin: 1rem 1rem 0 !important;
    }

    .call-timer {
        margin: 1rem 0rem !important;   
    }
    
    .dispensary-name-tag {
        margin: 1rem 0rem !important;
    }
    

    .spinner-border {
        margin: 1rem !important;
    }

    .dashboard-sidepanel--user.mobile-side-panel {
        display: block !important;
        background: white;
        border-radius: 0.3rem;
        width: unset !important;
    }

    .padding-left {
        padding-left:unset !important;
    }
    
    .margin-left {
        margin-left: unset !important;
    }

    .dashboard {
        padding: unset !important;
    }
    

    .dispensary-logo > img {
        width: 7rem !important;
    }


    .offline-message {
        display: none;
    }


    .App-logged-in {
        margin-bottom: 5rem;
    }

    .patients, .dispensaries, .cases, .call-queue {
        padding: unset !important;
        margin: 0px auto !important;
        max-width: calc(100vw - 2rem);
        min-width: calc(100vw - 2rem);;
    }

    .dispensary-details {
        div {
            flex-wrap: wrap;
            justify-content: center;
        }
    
        .dispensary-details--info {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        section {
            flex: 1;
        }
    } 

    .connect-div--show-call {
        position: absolute !important;
        left: 0rem !important;
        top: 0rem !important;
        width: 100% !important;
        height: 100% !important;
        max-width: unset !important;
        z-index: 2147483647 !important;
        margin: 0px auto;
    }

    #case-detail-header {
        flex-direction: column;
        height: unset !important;
        .case-detail-header-details {
            align-items: unset !important;
        }

        &>div {
            align-items: unset !important;
        }
    }

    .contact-details-mobile {
        div#slideOut {
            display: block !important;
        }
    }

    div#slideOut {
        position: fixed;
        top: 0px !important;
        right: 0px !important;
        height: 100px;
        display: none;
        top: 0px;
        width: 100% !important;
        height: 100% !important;
    }

    .MuiPaper-rounded {
        justify-content: unset !important;
    }

  }
  @media screen and (max-width: $medium) {
    .padding-left {
        padding-left:unset !important;
    }
    .margin-left {
        margin-left: unset !important;
    }

    .cannovate-banner {
        display: none !important;
    }

    .login-form-bottom {
        width: 75% !important;
    }


  }

  .contact-details-mobile {
    div#slideOut {
        display: none;
    }
}