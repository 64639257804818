@import './responsive.scss';

.dashboard-queue-box {
  padding: 1rem;
  border-radius: 4px;
  text-align: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 2rem;
  @media only screen and (max-width: $large) {
    margin-bottom: 1rem;
  }
}

.dashboard-queue-box > h2 {
  height: 25px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #010101;
  padding-left: 0.4375rem;
  padding-bottom: 1.125rem;
}

.padding-top {
  margin-top: 2rem;
}

th,
td {
  text-align: center;
  vertical-align: middle !important;
}

span {
  vertical-align: middle !important;
}

.border-success {
  border: 1px solid green;
  color: green;
}

.border-warning {
  border: 1px solid orange;
  color: orange;
}

.border-danger {
  border: 1px solid red;
  color: red;
}

.timeColor {
  text-align: center;
  border-radius: 10px;
  padding: 2px;
  font-size: 14px;
}

.badge {
  font-size: 60% !important;
}
