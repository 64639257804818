.bottom-navigation-bar {
    background-color: white;
    width: 100vw;
    position: fixed;
    bottom:0px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: unset !important;

    @media only screen and (min-width: 1221px) {
        display:none !important;
      }


    li{
        flex: 1;
        a {
        color: grey;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap: 0.5rem;
    }}
}