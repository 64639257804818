@import 'colors.scss';
@import 'global.scss';

#case-detail-container {
  flex: 1;
  flex-direction: column;
  border: 1px solid #e6eaee;
  margin: 1.5rem;
  border-radius: 4px;
  background-color: #fff;
}

.case-detail-container--background {
  background-color: $light1;
}

#case-detail-header {
  color: black;
  height: 78px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: solid 1px #e6eaee;
  background-image: $lightGradient1;
  display: flex;
  > div {
    display: flex;
    align-items: center;
    text-align: left;
    white-space: nowrap;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 1rem;
    padding: 1rem;
  }
}
#case-detail-header-title {
  color: black;
  font-family: 'Source Sans Pro';
  font-size: 25px;
}

#case-detail-header-details {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: black;
  width: 100%;
  div {
    width: 20%;
    // display: flex;
    // justify-content: center;
    span {
      font-weight: bold;
    }
  }
}

#case-detail-body {
  display: flex;
  flex-direction: column;
}

#case-notes {
  padding: 1rem;
  margin-bottom: 0;
  height: 100%;
  // border-bottom: 1px solid #e6eaee;
}

#case-note-text-area {
  height: 100%;
  border-radius: 4px;
  border: solid 1px #e6eaee;
  background-color: #ffffff;
  flex: 1;
  // margin: 1rem;
  padding: 1rem;
  position: relative;
  font-family: 'Source Sans Pro';
  font-size: 14px;
}

#case-note-text-area::placeholder {
  opacity: 0.73;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #354052;
}

#case-note-text-area:focus {
  outline: none;
}
#green-case-button.case-button.button-disabled-true {
  display: none;
}

@mixin case-button {
  width: 183px;
  height: 36px;
  border-radius: 4px;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
}

.green-case-button {
  @include case-button;
  border: solid 1px #2eb56a;
  outline: none !important;
  background: linear-gradient(to bottom, #75c699, #2eb56a);
  color: #ffffff;
}

.silver-case-button {
  @include case-button;
  border: solid 1px #ced0da;
  background: linear-gradient(to top, #f2f4f7, #ffffff);
  color: #1c1c1c;
  margin-right: 1rem;
}

.no-calls > h2 {
  position: absolute;
  left: 33%;
  top: 40%;
  transform: translate(-50%, -50%);
  color: #949597;
  font-size: 3rem;
}
.no-calls > svg {
  position: absolute;
  left: 33%;
  top: 40%;
  transform: translate(-50%, -50%);
  color: $light1;
}

.dark .no-calls > svg {
  color: $light1;
}

#case-detail-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

#case-log-logs {
  display: flex;
  padding: 1rem 2rem;
  margin: 1rem;
  max-height: 400px;
  overflow: scroll;
  border: 1px solid #e6eaee;
  font-family: 'Source Sans Pro';
  text-align: left;
  border-radius: 10px;
  .case-log-log-skill {
    font-size: 14px;
    font-weight: 600;
    > span:nth-of-type(1) {
      > span:nth-of-type(2) {
        font-weight: normal;
        font-style: italic;
      }
    }
  }
  .case-log-log-body {
    font-size: 14px;
    font-weight: 600;
  }
}

#case-log-checkboxes {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6eaee;
  text-align: left;
  label {
    font-family: 'Source Sans Pro';
    font-size: 14px;
    color: #354052;
    font-weight: 600;
    margin: 3px 20px 0px 10px;
  }
}

#case-topic-chips {
  padding: 1rem 1rem 1rem 2rem;
  border-bottom: 1px solid #e6eaee;
}

#topic-chips-input {
  display: flex;
  align-items: center;
}

.case-log-title {
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 600;
  color: #354052;
  margin-right: 2rem;
}

.chips-input {
  font-size: 14px !important;
}
.chips-input::placeholder {
  opacity: 0.73;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #354052;
}
#topic-chips-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-left: 70px;
}

.topic-chip {
  margin: 1rem 0.5rem 0rem;
  height: 32px;
  border-radius: 22.5px;
  background-color: $light1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  span {
    padding: 0 1rem;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-weight: 600;
    color: rgba(42, 44, 64, 0.82);
  }
  .topic-delete-button {
    cursor: pointer;
    color: #67ba06;
    font-weight: 600;
    font-size: 18px;
    position: relative;
    right: 7px;
  }

  .topic-delete-button:focus {
    outline: none;
  }
}

.delete-button {
  right: 25px;
  border-radius: 50%;
  height: 50px;
  top: -25px;
  width: 50px;
  cursor: pointer;
  font-size: 25px;
  color: #ef5754;
  border-style: none;
  border: 1px solid #dee2e6;
}

.delete-button:focus {
  outline: none;
}
.delete-button:active {
  outline: none;
}

.owncss {
  font-weight: bold;
  background-color: #75c699 !important;
}

.owncsstxt {
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  background-color: #f2f4f7 !important;
}

.card-style {
  max-height: 300px;
  min-height: 200px;
  width: 100%;
}

.scroll-custom {
  overflow: scroll;
}

.card-header {
  color: black;
  text-align: left;
  padding: 0.25rem 0.5rem !important;
}

.card-header:first-child {
  background: $lightGradient1;
  padding: 0.8rem 1rem 0.5rem 1rem !important;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin: 0 !important;
}

.card-text {
  width: 100%;
  border: 1px solid #d3d6da;
  background-color: #f2f4f7;
  text-align: left;
  font-size: 14px;
  min-height: fit-content OR 100px;
  padding: 1rem;
  margin-top: 1rem !important;
}

p {
  margin: 0 !important;
  margin-top: 1rem !important;
}

.title-accordian {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span.ownspan {
    float: right;
  }
}

.content-css {
  padding-top: 15px;
  padding-bottom: none;
}

/* Works on Firefox */
.scroll-custom {
  scrollbar-width: thin;
  scrollbar-color: rgb(153, 153, 153) rgb(245, 245, 245);
}

/* Works on Chrome, Edge, and Safari */
.scroll-custom::-webkit-scrollbar {
  width: 12px;
}

.scroll-custom::-webkit-scrollbar-track {
  background: rgb(245, 245, 245);
}

.scroll-custom::-webkit-scrollbar-thumb {
  background-color: rgb(153, 153, 153);
  border-radius: 20px;
  border: 3px solid rgb(245, 245, 245);
}

.w-100 {
  max-width: 100% !important;
}

#logDetails {
  max-width: 80% !important;
  height: 50vh !important;
}

.body-modal {
  width: auto;
  height: auto;
  max-height: 100%;
  max-height: calc(100vh - 210px);
  overflow-x: visible;
  overflow-y: hidden;
}

.border-btn {
  border: 1px solid rgba(142, 143, 145, 0.5) !important;
}

.align-center {
  text-align: center;
}

.my-modal {
  height: 500px !important;
}

.call-body {
  padding: 1rem !important;
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 15px !important;
}

#logDetails .modal-content {
  height: 60% !important;
}

.cases-table-data--name {
  text-align: center !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-position: center right calc(1.375em + 0.1875rem) !important;
}

.btn-primary,
.btn-success {
  border: solid 1px #2eb56a !important;
  outline: none !important;
  background: linear-gradient(to bottom, #75c699, #2eb56a) !important;
  box-shadow: none !important;
}

.dark {
  .case-log-title,
  #case-detail-header-title {
    color: black;
  }
  .case-detail-container--background {
    background-color: $light1;
  }

  .card-header:first-child {
    background: $darkGradient1;
    color: black;
  }

  .card {
    border: 1px solid $dark1;
    background-color: $dark2;
  }

  .silver-case-button {
    background: $darkGradient1;
  }

  #logDetails .modal-content {
    background-color: $dark1 !important;
  }

  #case-detail-header {
    background: $darkGradient1;
    border-bottom: solid 1px $dark2;
  }

  #case-log-checkboxes,
  #case-detail-container,
  #case-notes {
    border: 1px solid $dark2;
  }

  .case-detail-container--background,
  #case-detail-container {
    background-color: $dark1 !important;
  }

  #case-note-text-area {
    background-color: $dark4 !important;
    color: $light1;
    border: 1px solid $dark4;
  }

  .scroll-custom::-webkit-scrollbar-track {
    background: $dark2;
  }

  select,
  button {
    background: $darkGradient1;
    color: $light1;
    border: 1px solid $dark2;
  }
}
