@import 'colors.scss';

.App {
  text-align: center;
}
.App-logged-in {
  background-color: $light1;
  display: flex;
  min-height: 100vh;
}
button:active {
  box-shadow: none;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-table-message {
  text-align: center;
  margin-top: 4em;
  color: #7f8fa4;
}
button {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}
.app-loading-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.table-loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 178px;
  margin-left: 15rem;
}

.no-wrap {
  white-space: nowrap;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.border-left-18a5d6 {
  border-left: solid 0.25rem #18a5d6;
}
.border-left-89d011 {
  border-left: solid 0.25rem #75c699; //#89d011;
}
.border-left-19FF59 {
  border-left: solid 0.25rem #75c699;
}
.border-left-1bb934 {
  border-left: solid 0.25rem #1bb934;
}
.border-left-0ccff7 {
  border-left: solid 0.25rem #0ccff7;
}
.border-left-437c10 {
  border-left: solid 0.25rem #437c10;
}

.border-left-319145 {
  border-left: solid 0.25rem #319145;
}
.link-color-18a5d6 {
  color: #18a5d6;
}
.link-color-89d011 {
  color: #75c699; //#89d011;
}
.link-color-19FF59 {
  color: #75c699;
}
.link-color-1bb934 {
  color: #1bb934;
}
.link-color-0ccff7 {
  color: #0ccff7;
}
.link-color-437c10 {
  color: #437c10;
}
.link-color-319145 {
  color: #319145;
}
.background-color-18a5d6 {
  background-color: #18a5d6;
}
.background-color-89d011 {
  background-color: #75c699;
  // background-color: #89d011;
}
.background-color-19FF59 {
  background-color: #75c699;
}
.background-color-1bb934 {
  background-color: #1bb934;
}
.background-color-0ccff7 {
  background-color: #0ccff7;
}
.background-color-437c10 {
  background-color: #437c10;
}
.background-color-319145 {
  background-color: #319145;
}
.grid-template-columns-true {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  /* cursor: pointer; */
}
.grid-template-columns-false {
  grid-template-columns: minmax(0px, 110px) auto minmax(0px, 110px);
  cursor: pointer;
}
.flex-one {
  display: 'flex';
  flex: 1;
}
.flex-center {
  display: flex;
  align-items: center;
}
.list-style-none {
  list-style: none;
}
.messages {
  position: absolute;
  top: 15px;
  right: 15px;
}

.outer-container--toast {
  position: absolute;
  top: 25;
  right: 100;
  z-index: 1000;
}
.empty-table-message.empty-table-message-margin {
  margin: 1rem 0;
}
.justify-center {
  justify-content: center;
}
.capital-text {
  text-transform: capitalize;
}
.width-one-hundred {
  width: 100%;
}

.logout-button {
  margin-top: 544px;
  margin-left: 300px;
}

.dashboard-sidepanel > div > .padding-left,
.dashboard.padding-left,
.dispensaries.padding-left,
.padding-left,
.patients.padding-left {
  padding-left: 19rem;
}

.dispensary-name-tag {
  color: #fff;
}

.case-log-checkboxes--box {
  margin: 0;
}
.case-log-logs-div {
  display: flex;
  align-items: 'flex-end';
  flex-wrap: 'wrap';
}
.case-logs-log--upper {
  margin-top: -2px;
}
.case-log-checkboxes--form {
  margin: 0;
}

.call-slideout.call-slideout-true {
  border-right: 1px solid #e3e6ea;
}
.call-slideout.call-slideout-false {
  border-right: none;
}
.call-contact-is-phone-true {
  right: 15rem;
}
.call-contact-is-phone-false {
  right: 20rem;
}

.dashboard-queue-cursor-true {
  cursor: pointer;
}
.dashboard-queue-cursor-false {
  cursor: auto;
}

.dashboard-queue--element-cell {
  text-align: center;
}

.dispensary-info--parent {
  background-color: #fff;
  padding: 1rem;
}
.patient-parent {
  width: 100%;
  padding: 1rem 2rem 0 0;
}

.input-container > .input-box-true {
  cursor: not-allowed;
}
.input-container > .input-box-false {
  cursor: auto;
}
#connectDiv {
  display: none;
  position: absolute;
}

.connect-div--show-call {
  position: relative;
  min-width: 15rem;
  max-width: 15rem;
  height: 100%;
  padding-top: 15vh;
  border-left: 1px solid #e3e6ea;
  background-color: #fff;
}

.contact-case {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.calling-customer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-case-patient-name {
  font-family: Source Sans Pro;
  font-size: 21px;
  font-weight: bold;
  color: #1c1c1c;
  margin-bottom: 1rem;
}
.contact-case-patient-number {
  font-family: Source Sans Pro;
  font-size: 15px;
  color: #1c1c1c;
}

.calling-customer--spinner {
  color: #1c1c1c;
}

.contact-state {
  margin: 1rem 0rem;
}

.padding-left.dispensary-header {
  width: 100%;
}

.agent-online-true {
  color: #75c699;
}
.agent-online-false {
  color: #0c0f13;
}

.form-group {
  margin-bottom: 0 !important;
}

.dark {
  .App-logged-in {
    background-color: $dark1;
  }

  .MuiPaper-root {
    background-color: $dark3;
  }

  .MuiTableCell-root {
    border-bottom: 1px solid $dark3;
  }

  .MuiTableCell-body,
  .cases-table-data--name a {
    color: $light4;
  }

  .WithStyles\(ForwardRef\(TableRow\)\)-root-9:nth-of-type(odd) {
    background-color: $dark4;
  }

  .cases > h2,
  .cases-table-headers,
  .cases-button {
    color: $light1;
  }

  .nav-tabs {
    border-bottom: 1px solid $dark2 !important;
  }

  .contact-case-patient-name,
  .contact-case-patient-number,
  .contact-case,
  .call-timer {
    color: $light2;
  }

  .call-buttons button.end-call-button {
    background: red;
  }
  .save-button {
    background: $cannovateGreen;
  }
}

.login-form-bottom {
  width: 50% !important;

  label {
      width: 100% !important;
  }
}

.margin-left {
  margin-left: 19rem !important;
  width: 100%;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}
