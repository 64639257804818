.red {
  color: red;
}
.green {
  color: green;
}

#call-details-tabpane-product-reservations {
  border-top: 1px solid #dee2e6;
  border-top-right-radius: 4px;
}

.product-serach-results-table {
}

.product-serach-results-table {
  box-shadow: 10px 10px 10px #ddd;
  border-radius: 10px;
  border: 2px solid #999;

  td {
    cursor: pointer;
  }

  th,
  td {
    height: 50px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
}

input.item-count {
  text-align: center;
  height: 25px;
  border: 0px;
  border-bottom: 1px solid;
}

.product-res-modal-content,
.product-res-modal-footer {
  background-color: white;
  overflow-y: unset;
  max-height: unset;
}

.product-res-modal-title {
  padding: 0.5rem !important;
  font-weight: 600 !important;
}
