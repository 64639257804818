@import './colors.scss';

.activeNav svg {
  color: $cannovateGreen;
}

h3,
h2,
h1 {
  color: $dark1 !important;
}

.MuiTablePagination-input,
.MuiTablePagination-caption,
.MuiIconButton-label,
.MuiSelect-icon {
  color: $dark1 !important;
}

.card {
  margin-top: 1rem;
}

.dark {
  h3,
  h2,
  h1 {
    color: $light1 !important;
  }

  .MuiTablePagination-input,
  .MuiTablePagination-caption,
  .MuiIconButton-label,
  .MuiSelect-icon {
    color: $light1 !important;
  }

  tr {
    background-color: $dark3;
  }

  .outer-container {
    border: 1px solid $dark3;
  }

  .nav-tabs .nav-link {
    border: 1px solid $dark2;
    border-bottom: 1px solid $dark1;
  }

  select,
  button {
    background: $darkGradient1;
    color: $light1;
    border: 1px solid $dark2;
  }
}

.nav-tabs .nav-link.disabled {
  color: #86868657 !important;
}

.call-forward-button {
  margin: 1rem;
  border-radius: 0.3rem;
  background-color: #0277bd;
  border: 0px transparent;
  color:white;
  
}